<template>
  <div>
    <p>Edit the event here</p>
  </div>
</template>

<script>
export default {
  props: ['event']
}
</script>

<style lang="scss" scoped></style>
