<template>
  <div>
    <p>Register for the event here</p>
    <p>Linda</p>
  </div>
</template>

<script>
export default {
  props: ['event']
}
</script>
<style lang="scss" scoped></style>
